<template lang="html">
    <div>
        <b-navbar type="light" class="nav-bg" fixed="top" style="position: fixed;height: 50px;">
            <router-link to="/dashboard" style="margin-left: 20px; font-size: 32px;">
                    <b-icon icon="arrow-left" font-scale="1"></b-icon>
            </router-link>
            <span>Return List</span>
        </b-navbar>
        <div class="container-fluid" style="margin-top: 16%;margin-bottom: 20%;">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <b-row  style="margin-top: 2%;">
                <b-col>
                    <b-card v-for="complete in completes" class="mt-2">
                        <b-row class="justify-content-md-center">
                            <b-col>
                                <h3>{{complete.day}} <small> ( {{complete.transporter}} ) </small></h3>
                            </b-col>
                        </b-row>
                        <hr class="mt-2 mb-2">
                        <b-row class="justify-content-md-center">
                            <b-col cols="6">
                                <p v-for="item in complete.item">{{item.itemName}} : <span class="badge badge-success mr-2">{{item.quantity}}</span>
                                <span class="badge badge-danger" v-if="item.distri_damage_quantity"> {{item.distri_damage_quantity}}</span></p>
                            </b-col>
                            <b-col cols="6" v-if="complete.status != 'pending'">
                                <span class="text-capitalize">{{complete.status}}</span>
                            </b-col>   
                            <b-col cols="6" v-if="complete.status == 'pending'">
                                <select class="form-control mt-2" v-model="status">
                                    <option value="pending">Pending</option>
                                    <option value="approved">Approve</option>
                                    <option value="reject">Reject</option>
                                </select>
                                <button @click="submitReturn(complete.id)" class="btn btn-primary mt-2" style="width: 100%;">Submit</button>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>    
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'dashboard',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                status: 'pending',
                completes: []
            }
        },
        computed: {
            ...mapState({})
        },
        methods: {
            ...mapActions({
                completeReturnItemAction: 'completeReturnItemAction',
                statusChangeAction: 'statusChangeAction'
            }),
            async completeInfo() {
                await this.completeReturnItemAction().then(res => {
                    if (res.status == "success") {
                        this.completes = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            async submitReturn(id) {
                this.isLoading = true
                let option = {
                    status: this.status,
                    returnId: id
                }
                await this.statusChangeAction({
                    ...option
                }).then(res => {
                    this.isLoading = false
                    this.status = "pending"
                    this.completeInfo()
                }).catch(err => this.isLoading = true)
            }

        },
        async mounted() {
            this.isLoading = true
            this.completeInfo()
        }
    }
</script>

<style scoped lang="scss">
    .status-info {
        width: 100%;
        height: 100vh;
        background: hsl(210 11% 92% / 1);
        z-index: 9999;
        position: absolute;
        top: 52px;
    }
</style>